import React, {useEffect, useRef, useState} from 'react';

import './Checkbox.scss';
import PropTypes from 'prop-types';
import {Field} from "redux-form";
import Icon from "../icon/Icon";
import FieldError from "../fiedlError/FieldError";


const RenderField = (props) => {
    const {
        input,
        meta,
        id,
        label,
        disabled,
        indeterminate,
        marginBottom,
        onChangeCallback,
        schemaValues,
        info,
        onClick,
        customError,
        readOnly,
    } = props;

    const {error, touched} = meta;
    const isError = touched && (error || customError) ;

    const [inputValue, setInputValue] = useState(false);
    const [isMoreThenOneLineDescription, setIsMoreThenOneLineDescription] = useState(false)
    const checkBoxRef = useRef();


    useEffect( () => {
        let value = input.value;
         if (schemaValues instanceof Object) {
             value = schemaValues[input.value];
         }
        setInputValue(value || false);

    }, [input.value, touched]);

    useEffect(() => {
        if (onChangeCallback instanceof Function) {
            onChangeCallback(inputValue, meta);
        }
    }, [inputValue, meta.touched])
    useEffect(()=>{
       if(checkBoxRef.current.clientHeight > 24){
           setIsMoreThenOneLineDescription(true)
       }
    },[])

    const onChangeHandler = (e) => {
        if (readOnly) {
            return;
        }
        const value = e.currentTarget.checked;
        setInputValue(value);
        let inputValue = value;
        if (schemaValues instanceof Object) {
            for (const prop in schemaValues) {
                if (schemaValues.hasOwnProperty(prop) && schemaValues[prop] === value) {
                    inputValue = prop;
                    break;
                }
            }
        }
        input.onChange(inputValue);
    }

    const onClickHandler = (e) => {
        onChangeHandler(e);
        input.onBlur();
        if(onClick instanceof Function) {
            onClick(e);
        }
    }

    return (
        <>
            {(isError && customError) && <FieldError error={customError} />}
            <div
                className={`agp-checkbox ${disabled ? 'agp-checkbox_disabled': ''} ${input.value ? 'agp-checkbox_checked': ''} ${ isError ? 'agp-checkbox_error': ''} agp-checkbox_${marginBottom}`}
            >
                <div className={`agp-checkbox_center ${isMoreThenOneLineDescription ? 'agp-checkbox_start' : ''}`} ref={checkBoxRef}>
                    <input
                        name={input.name}
                        id={id}
                        className={`agp-checkbox__input ${indeterminate ? 'agp-checkbox__input_indeterminate' : ''}`}
                        disabled={disabled}
                        type={"checkbox"}
                        checked={inputValue}
                        onChange={onChangeHandler}
                        onClick={onClickHandler}
                        readOnly={readOnly}
                    />
                    <label htmlFor={id} className={`agp-checkbox__visual ${isMoreThenOneLineDescription ? 'checkbox__visual_margin' : ''}`}>
                        {!!input.value && <Icon width={10} height={8} color={"#FFFFFF"} className={'agp-checkbox__visual_icon'} icon={`${indeterminate ? 'Checkbox/Dash' : 'Checkbox/Check'}`} />  }
                    </label>
                    <label htmlFor={id} className="agp-checkbox__label">
                        {label}
                    </label>
                </div>
                {info && <span className="agp-checkbox__info">{info}</span>}
            </div>
        </>
    )
}

const Checkbox = (props) => {
    const {
        id,
        name,
        label,
        validate,
        disabled,
        info,
        indeterminate,
        marginBottom,
        onChangeCallback,
        schemaValues,
        onClick,
        customError,
        readOnly,
    } = props;

    return (
        <Field
            component={RenderField}
            id={id}
            name={name}
            label={label}
            validate={validate}
            disabled={disabled}
            info={info}
            indeterminate={indeterminate}
            marginBottom={marginBottom}
            onChangeCallback={onChangeCallback}
            schemaValues={schemaValues}
            onClick={onClick}
            customError={customError}
            readOnly={readOnly}
        />
    );
};

export default Checkbox;
Checkbox.defaultProps = {
    validate: [],
    disabled: false,
    indeterminate: false,
    marginBottom:'lg',
    onChangeCallback: null,
    schemaValues: null,
    customError: null,
    readOnly: false,
};


Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    info: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    validate: PropTypes.arrayOf(PropTypes.func), // TODO не удалось найти правильный тип для этого свойства, но вроде и так неплохо
    disabled: PropTypes.bool,
    indeterminate: PropTypes.bool,
    marginBottom: PropTypes.oneOf(['xs', 'md', 'lg']),
    onChangeCallback: PropTypes.func,
    schemaValues: PropTypes.object, //объект для перемапливания, пример: {"0": false, "1": true}
    customError: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    readOnly: PropTypes.bool,
};

