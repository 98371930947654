import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './Tooltip.scss';

const Tooltip = (props) => {
    const {
        children,
        customClass,
        position,
        delayShow,
        delayHide,
        content,
        id,
        windowWidth,
        customZIndex,
    } = props

    let timeout;
    let timeoutHide;
    const [active, setActive] = useState(false);
    const [_position, setPosition] = useState(position)
    const tooltipRef = useRef(null);
    const isMobile = props.windowWidth <= 768;


    useEffect(() => {
        if(!tooltipRef || !tooltipRef.current) return;
        if(active) {
            setPositionHandler(tooltipRef.current)
        }
    },[active])


    const setPositionHandler = (el) => {
        const left = el.getBoundingClientRect().left;
        const right = el.getBoundingClientRect().right;

        if(right > windowWidth) {
            setPosition('top-right');
        }
        if(left <= 0 ) {
            setPosition('top-left');
        }
    }

    const showTipMobile = (e) => {
        e.stopPropagation();
        showTip()
    }

    const showTip = () => {
        clearInterval(timeoutHide);
        timeout = setTimeout(() => {
            setActive(true);
        }, delayShow );
    };

    const hideTip = () => {
        clearInterval(timeout);
        timeoutHide = setTimeout(() => {
            setActive(false);
        }, delayHide)
    };

    return (
        <div onMouseEnter={!isMobile ? showTip : null} onMouseLeave={hideTip} className="agp-tooltip" aria-describedby={id} onClick={isMobile ? showTipMobile : null}>
            {children}

            {active && (
                <div
                    role="tooltip"
                    style={{zIndex: `${customZIndex || '999'}`}}
                    className={`agp-tooltip__tip agp-tooltip__tip_${_position || 'top'}${customClass}`}
                    id={id}
                    ref={tooltipRef}
                >
                    {content}
                </div>
            )}
        </div>
    )
};

const mapStateToProps = store => {
    return {
        windowWidth: store.metaState.windowWidth,
    }
}

export default connect(mapStateToProps)(Tooltip);


Tooltip.propTypes = {
    customClass: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    position: PropTypes.oneOf(['top', 'top-right', 'top-left', 'bottom', 'bottom-right', 'bottom-left']),
    delayShow: PropTypes.number,
    delayHide: PropTypes.number,
    content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    id: PropTypes.string,
    customZIndex: PropTypes.string,
};

Tooltip.defaultProps = {
    customClass: '',
    delayShow: 0,
    delayHide: 0,
    id: '',
};
