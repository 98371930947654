import React from 'react';
import PropTypes from "prop-types";

import {blue60} from "../vars.js";
import './Loader.scss'


const Loader = props => {
    const {width, height, color = blue60, marginRight = 10, marginLeft, strokeWidth, className, widthContainer, centered} = props;
    return (
        <div id="agp-loader__wrapper" style={{marginRight: centered ? 'auto' : `${marginRight}px`, marginLeft: centered ? 'auto' :`${marginLeft}px`, width: widthContainer}} className={className}>
            <div className={`agp-loader__mainLoader ${centered ? 'agp-loader__mainLoader_centered' : ''}`}  style={{width:width}} >
                <div  className={`agp-loader__loader ${centered ? 'agp-loader__loader_centered' : ''}`}>
                    <svg className="agp-loader__circular" viewBox="25 25 50 50" fill={color} width={width} height={height} >
                        <circle className="agp-loader__loaderPath" cx="50" cy="50" r="20" fill="none" stroke={color}
                                strokeWidth={strokeWidth}/>
                    </svg>
                </div>
            </div>
        </div>
    );
};

Loader.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color:PropTypes.string,
    marginRight:PropTypes.number,
    marginLeft: PropTypes.number,
    strokeWidth:PropTypes.number,
    className: PropTypes.string,
    widthContainer: PropTypes.string,
    centered: PropTypes.bool,
};

Loader.defaultProps = {
    width: 20,
    height: 20,
    marginRight: 10,
    marginLeft: 0,
    strokeWidth: 5,
    className: '',
    widthContainer: 'auto',
    centered: false,
};


export default Loader;
