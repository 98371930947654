import React from 'react';
import PropTypes from 'prop-types';

import "./FormBlock.scss";

const FormBlock = (props) => {
    const {
        children,
        customMarginTop,
        customMarginBottom,
        customPaddingLeft,
        customPaddingRight,
        withBottomBorder,
        withTopBorder,
        direction,
        alignItems,
        verticalPaddings,
        ...rest
    } = props;
    const marginTop = customMarginTop !== undefined ? customMarginTop : 24;
    const marginBottom = customMarginBottom !== undefined ? customMarginBottom : 0;
    const paddingLeft = customPaddingLeft !== undefined ? customPaddingLeft : 0;
    const paddingRight = customPaddingRight !== undefined ? customPaddingRight : 0;

    const alignStyles = alignItems ? {
        alignItems: alignItems,
    } : {};

    return (
        <div
            className={`agp-formBlock ${direction ? `agp-formBlock_${direction}` : ''} ${withTopBorder ? 'agp-formBlock_withTopBorder' : ''} ${withBottomBorder ? 'agp-formBlock_withBottomBorder' : ''} agp-formBlock__verticalPaddings_${verticalPaddings}`}
            style={{
                marginBottom: `${marginBottom}px`, marginTop: `${marginTop}px`, paddingLeft: `${paddingLeft}px`, paddingRight: `${paddingRight}px`, ...alignStyles,
            }}
            {...rest}
        >
            {children}
        </div>
    );
};

FormBlock.defaultProps = {
    withTopBorder: false,
    withBottomBorder: false,
    verticalPaddings: 'default',
    direction: 'column',

}

FormBlock.propTypes = {
    customMarginTop: PropTypes.number,
    customMarginBottom: PropTypes.number,
    customPaddingLeft: PropTypes.number,
    customPaddingRight: PropTypes.number,
    withTopBorder: PropTypes.bool,
    withBottomBorder: PropTypes.bool,
    verticalPaddings: PropTypes.oneOf(['default', 'small']),
    direction: PropTypes.oneOf(['row', 'column']),
    alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'stretch']),
}

export default FormBlock;