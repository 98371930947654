import React, {useEffect, useRef, useState} from 'react';

import './FieldError.scss';
import PropTypes from 'prop-types';
import Icon from "../icon/Icon";


const FieldError = ({error, customClass, iconWidth, iconHeight}) => {
    return (
        <div
            className={`agp-field-error ${customClass}`}
        >
            <div
                style={{minWidth: `${iconWidth}px`}}
                className="agp-field-error__iconWrapper"
            >
                <Icon width={iconWidth} height={iconHeight} icon="Notification/Error" />
            </div>
            <span dangerouslySetInnerHTML={{ __html: error }} />
        </div>
    )
}

export default FieldError;


FieldError.propTypes = {
    error: PropTypes.string.isRequired,
    customClass: PropTypes.string,
    iconWidth: PropTypes.number,
    iconHeight: PropTypes.number,
};

FieldError.defaultProps = {
    customClass: '',
    iconWidth: 12,
    iconHeight: 12,

};
