import React, { useEffect } from 'react';
import ModalDialog from "../ModalDialog";
import Icon from "../../icon/Icon";
import PropTypes from "prop-types";
import Button from "../../button/Button";
import Heading from "../../heading/Heading";
import './ModalNotification.scss'
import ButtonsBlock from "../../buttonsBlock/ButtonsBlock";

const ModalNotification = (props) => {

    const {
        onHide,
        centered,
        withCloseBtn,
        isShow,
        title,
        children,
        buttonTitle,
        onSubmit,
        variant,
        customButtonsBlock,
        description,
        redirectUrl,
        afterHandler,
        onConstruct,
    } = props;

    let icon = null;

    useEffect(() => {
        if (onConstruct instanceof Function) {
            onConstruct()
        }
    }, [onConstruct])

    switch (variant) {
        case 'success':
            icon = <Icon icon={'Notification/SuccessWithOval'} width={40} height={40} />
            break;
        case 'warning':
            icon = <Icon icon={'Notification/WarningWithOval'} width={40} height={40} />
            break;
        case 'error':
            icon = <Icon icon={'Notification/ErrorWithOval'} width={40} height={40} />
            break;
        case 'error-grey':
            icon = <Icon className="error-grey" icon={'Notification/ErrorWithOval'} width={40} height={40} />
            break;
        case 'info':
            icon = <Icon icon={'Notification/InfoWithOval'} width={40} height={40} />
            break;
    }

    const handleModalSubmit = () => {
        if (onSubmit instanceof Function) {
            onSubmit();
        }
        onClose();
    }


    const onClose = () => {
        if (redirectUrl) {
            window.location.href = redirectUrl;
        }
        if (afterHandler instanceof Function) {
            afterHandler();
        }
        onHide();
    }


    return (
        <ModalDialog onHide={onClose} centered={centered} withCloseBtn={withCloseBtn} isShow={isShow} type={'info'}>
            <div className={'agp-modal-notification'}>
                <div className={'agp-modal-notification__header'}>
                    <div className={'agp-modal-notification__header__icon'}>
                        {icon}
                    </div>
                    {title ? <div className={'agp-modal-notification__header__title'}>
                        <Heading variant='h2'>{title}</Heading>
                    </div> : null
                    }
                </div>
                <div className={'agp-modal-notification__body'}>
                    {children}
                </div>
                {description ?
                    // <div className={'agp-modal-notification__description'}>
                    <div className={'agp-modal-notification__body'}>
                        {description}
                    </div> : null
                }
                <div className={'agp-modal-notification__footer'}>
                    {customButtonsBlock ?
                        customButtonsBlock :
                        <ButtonsBlock>
                            <Button onClick={handleModalSubmit}>{buttonTitle}</Button>
                        </ButtonsBlock>
                    }
                </div>
            </div>
        </ModalDialog>
    );
};
ModalNotification.propTypes = {
    onHide: PropTypes.func,
    centered: PropTypes.bool,
    isShow: PropTypes.bool,
    withCloseBtn: PropTypes.bool,
    title: PropTypes.string,
    buttonTitle: PropTypes.string,
    onSubmit: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
    customButtonsBlock: PropTypes.element,
    description: PropTypes.string,
    redirectUrl: PropTypes.string,
    afterHandler: PropTypes.func,
    onConstruct: PropTypes.func,
};

ModalNotification.defaultProps = {
    onHide: undefined,
    centered: true,
    isShow: false,
    withCloseBtn: true,
    buttonTitle: 'Продолжить',
    customButtonsBlock: null,
    onSubmit: () => { },
    onConstruct: () => { },
};

export default ModalNotification;