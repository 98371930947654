import React, {useMemo, useState} from 'react';
import PropTypes from "prop-types";
import './Button.scss';
import {blue60, blue70, gray60, white, green} from "../vars.js";
import Loader from "../loader/Loader";

const Button = (props) => {
    const {
        type,
        variant,
        size,
        disabled,
        customClass,
        onClick,
        startIcon,
        endIcon,
        children,
        fullWidth,
        id,
        isFetching,
        textLeft,
    } = props;

    const [active, setActive] = useState(false);
    const loaderColor = useMemo(() => {
        if (variant === 'primary') {
            if (disabled) {
                return gray60;
            } else {
                return white;
            }
        } else if (variant === 'secondary') {
            return blue70;
        } else if (variant === 'ghost') {
            return blue60;
        } else if (variant === 'whiteBordered' || variant === 'text') {
            return gray60;
        }

    }, [variant, disabled]);

    const loaderSize = useMemo(() => {
        if (size === 40) {
            return 18;
        } else if (size === 32) {
            return 16;
        } else if (size === 48) {
            return 20;
        } else if (size === 56) {
            return 22;
        } else {
            return 18;
        }
    }, [size]);

    const handleClick = e => {
        createRipple(e);
        
        if(!active) {
            setActive(true);
        }

        if (isFetching) {
            e.preventDefault();
            return;
        }

        if(onClick instanceof Function) {
            onClick(e);
        }
    }

    const createRipple = event => {
        const button = event.currentTarget;

        const circle = document.createElement("span");
        const diameter = Math.max(button.clientWidth, button.clientHeight);
        const radius = diameter / 2;

        circle.style.width = circle.style.height = `${diameter}px`;
        circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
        circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
        circle.classList.add("ripple");

        const ripple = button.getElementsByClassName("ripple")[0];

        if (ripple) {
        ripple.remove();
        }

        button.appendChild(circle);
    }
    const btnClass =
        `agp-button agp-button_${variant} ` +
        `agp-button_${size} ` +
        `${active ? 'agp-button_active ' : ''} ` +
        `${!children ? 'agp-button_square ' : ''} ` +
        `${customClass? customClass + ' ' : ''}` +
        `${fullWidth ? 'agp-button_full-width ' : '' } ` +
        `${variant === 'ghost' ? 'agp-button_ghost ' : ''} ` +
        `${isFetching ? 'agp-button__fetching' : ''}` +
        `${textLeft ? 'agp-button__text-left' : ''}`;


    return (
        <button
            id={id}
            type={type}
            className={btnClass}
            disabled={disabled}
            onClick={handleClick}
            onMouseDown={e => e.preventDefault()}
        >
            {startIcon ? <span className="agp-button__icon agp-button__icon_start " color={disabled ? gray60 : undefined}>{startIcon}</span> : null}
            <span className="agp-button__content">{children}</span>
            {endIcon ? <span className="agp-button__icon agp-button__icon_end">{endIcon}</span> : null}
            {isFetching ? <Loader className="agp-button__loader" color={loaderColor} height={loaderSize} width={loaderSize} /> : null}
        </button>
    );
};

Button.defaultProps = {
    type: 'button',
    variant: 'primary',
    size: 48,
    disabled: false,
    customClass: '',
    children: '',
    startIcon: undefined,
    endIcon: undefined,
    onClick: undefined,
    fullWidth: false,
    isFetching: false,
    textLeft: false,
};

Button.propTypes = {
    id: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    variant: PropTypes.oneOf(['primary', 'secondary', 'ghost', 'whiteBordered', 'text']),
    size: PropTypes.oneOf([56, 48, 40, 32]),
    disabled: PropTypes.bool,
    customClass: PropTypes.string,
    startIcon: PropTypes.element,
    endIcon: PropTypes.element,
    onClick: PropTypes.func,
    fullWidth: PropTypes.bool,
    isFetching: PropTypes.bool,
    textLeft: PropTypes.bool,
};

export default Button;
