import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from "redux-form";
import Icon from "../icon/Icon";
import FieldError from "../fiedlError/FieldError";
import BalloonHelp from "../balloonHelp/BalloonHelp";
import { blue60 } from "../vars.js";
import Tooltip from "../tooltip/Tooltip.jsx";

import './Select.scss';

const RenderField = (props) => {
    const {
        input,
        meta,
        id,
        label,
        info,
        disabled,
        options,
        infoHint,
        labelHint,
        onChangeCallback,
        size,
        isValid,
        readOnly,
        ghost,
        required,
        absoluteError,
    } = props;

    const { error, touched } = meta;
    const isError = touched && error;
    const { onChange, onBlur } = input;
    const list = useRef();
    const [currentTitle, setCurrentTitle] = useState(null);

    const [isOpen, setOpen] = useState(false);
    const handleOpen = (e) => {
        e.preventDefault();
        setOpen(!isOpen);
    }

    const handleClickOutside = (e) => {
        if (list.current && list.current.contains(e.target)) {
            return;
        }
        setOpen(false);
        onBlur();
    };

    const handleOnMouseDown = (e) => { // убираем фокус на клик
        e.preventDefault();
    }

    const getCurrentTitle = (options) => {
        if (!input.value || !options || !options.length) {
            return null;
        }

        const indexTitle = options.find(i => i.value == input.value)

        if (indexTitle) {
            return indexTitle.title;
        }

        return null;
    }

    useEffect(() => {
        setCurrentTitle(getCurrentTitle(options));
    }, [options, input.value])

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        if (touched) {
            onBlur();
        }
    }, [touched, input.value]);

    useEffect(() => {
        if (onChangeCallback instanceof Function) {
            onChangeCallback(input.value, meta);
        }
    }, [input.value, meta.touched])

    const handleOnChange = (e) => {
        setCurrentTitle(e.currentTarget.textContent);

        if (onChange instanceof Function) {
            onChange(e.currentTarget.dataset.value);
        }
        setOpen(false);
    }
    return (
        <div className={`agp-select ${size ? `agp-select_${size}` : ''} ${ghost ? 'ghost' : ''} ${required ? 'field_required' : ''}`} ref={list}>
            {!ghost &&
                <div className={`select__label_wrapper ${absoluteError ? 'absolute-error' : ''}`}>
                    <label className={"agp-select__label"} htmlFor={id}>{label} {isValid
                        ? <Tooltip content={isValid.COMMON} id='isValidInfo'>
                            <span className="agp-select__isValid"><Icon icon="Checkbox/Check" color={blue60} width={10} height={8} /></span>
                        </Tooltip>
                        : null}</label>
                    {labelHint && <BalloonHelp content={labelHint} />}
                    {isError && absoluteError && <BalloonHelp content={error} type={'error'} />}
                </div>
            }
            {info && <div className="agp-select__info">{info} {infoHint && <BalloonHelp content={infoHint} />}</div>}
            {isError && !absoluteError && <FieldError error={error} />}
            <div className={`agp-select__input ${isError ? 'agp-select__input_error' : ''}`}>
                <button
                    className={"agp-select__input__button"}
                    aria-haspopup="listbox"
                    role={'button'}
                    onMouseDown={handleOnMouseDown}
                    onClick={handleOpen}
                    id={id}
                    disabled={disabled}
                >
                    {currentTitle ? currentTitle : <span>Выберите из списка</span>}
                </button>

                {isOpen && options && options.length && !readOnly ?
                    <ul tabIndex="-1" role="listbox" className={"agp-select__input__list"}>
                        {
                            options.map(({ value, title }) => {
                                return <li role="option" key={value}
                                    className={"agp-select__input__list__option"}
                                    onClick={handleOnChange}
                                    onMouseDown={handleOnMouseDown}
                                    data-value={value}>
                                    {title}
                                    {title === currentTitle && value == input.value &&
                                        <div>
                                            <Icon icon={"Checkbox/Check"}
                                                className={"agp-select__input__list__option__icon"}
                                                color={'#0E0E0F'}
                                                height={6.85}
                                                width={8.6} />
                                        </div>
                                    }
                                </li>
                            })
                        }
                    </ul> : null
                }
                <div>
                    {!ghost ?
                        <Icon icon={"Select/Arrow"} className={"agp-select__input__icon"} color={'#0E0E0F'} height={12} width={5.3} /> :
                        <Icon icon={"Arrow/Down"} className={"agp-select__input__icon"} color={'#fff'} height={6.85} width={8.6} />
                    }
                </div>
            </div>
        </div>
    )
}

const Select = (props) => {
    const {
        id,
        name,
        label,
        validate,
        disabled,
        info,
        options,
        labelHint,
        infoHint,
        onChangeCallback,
        size,
        isValid,
        readOnly,
        ghost,
        required,
        absoluteError,
    } = props;

    return (
        <Field
            ghost={ghost}
            component={RenderField}
            id={id}
            name={name}
            label={label}
            validate={validate}
            disabled={disabled}
            info={info}
            options={options}
            labelHint={labelHint}
            infoHint={infoHint}
            onChangeCallback={onChangeCallback}
            size={size}
            isValid={isValid}
            readOnly={readOnly}
            required={required}
            absoluteError={absoluteError}
        />
    );
};

export default Select;


Select.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.arrayOf(PropTypes.func),
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired || PropTypes.number.isRequired, //TODO опредилеиться с типом value
        title: PropTypes.string.isRequired
    })).isRequired,
    labelHint: PropTypes.string,
    info: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    infoHint: PropTypes.string,
    onChangeCallback: PropTypes.func,
    size: PropTypes.oneOf(['small', 'default', 'halfWidth', 'fullWidth']),
    isValid: PropTypes.bool,
    readOnly: PropTypes.bool,
    ghost: PropTypes.bool,
    required: PropTypes.bool,
    absoluteError: PropTypes.bool
};

Select.defaultProps = {
    validate: [],
    disabled: false,
    labelHint: '',
    infoHint: '',
    size: 'fullWidth',
    isValid: false,
    readOnly: false,
    ghost: false,
    required: false,
    absoluteError: false
};
