import React, { useContext } from 'react';
import Link from "../../common/link/Link";
import Context from '../../utils/context';
import logo from '../../img/logo-w.svg'
import Button from "../../common/button/Button";
import './template.scss'
import Cookies from 'universal-cookie';
import Heading from '../../common/heading/Heading'
import moment from 'moment';
import sendReqest from "../../middleware/sendRequest"
import { useDispatch } from "react-redux";
import { hideModal, showModal } from "../../store/actions/ModalActions";
import * as modals from "../../store/actions/ModalConstants";
import { deepFind } from '../../../backend/models/helpers';
import PropTypes from 'prop-types';
const cookies = new Cookies();

const Template = (props) => {
    const {
        colorVariant,
        caption,
        menu,
        children,
        customCaption,
        customClass,
    } = props;

    const context = useContext(Context)
    const dispatch = useDispatch();

    const generatePageMenu = () => {
        return menu.map(el => {
            return el
        })
    }
    return (
        <div id="work-area" className={`${colorVariant} ${customClass}`}>
            <div className='caption-block'>
                {!customCaption ?
                    <Heading variant='h1'>{caption}</Heading> :
                    customCaption
                }
                {menu && menu.length > 0 &&
                    <ul className='page-menu'>
                        <>
                            {generatePageMenu()}
                        </>
                    </ul>
                }
            </div>
            <div className='content'>
                {children}
            </div>
        </div>


        // <div className='header'>
        //     <div className='logo'>
        //         <img src={logo} alt="AG.Platform" />
        //     </div>
        //     <nav>
        //         <Link exact to='/profile' routerLink={true} activeClassName={'active'} isUnderlined={false} customClass={'color-white-green'}>
        //             Профиль
        //         </Link>
        //         <Link to='/profile/projects' routerLink={true} activeClassName={'active'} isUnderlined={false} customClass={'color-white-green'}>
        //             {/* style={{ padding: '5px' }} */}
        //             Проекты
        //         </Link>
        //     </nav>
        //     <Button
        //         size={40}
        //         variant="ghost"
        //         onClick={() => { logout() }}
        //         customClass={'logout'}
        //     >
        //         Выйти
        //     </Button>
        //     {/* {!context.authState
        //         ? <button onClick={() => context.authObj.login()}>Login</button>
        //         : <button onClick={() => context.authObj.logout()}>Logout</button>
        //     } */}
        // </div>
    )
};





export default Template;

Template.propTypes = {
    colorVariant: PropTypes.oneOf(['gray-white', 'white-gray']),
    caption: PropTypes.string,
    menu: PropTypes.array,
    customCaption: PropTypes.element,
    customClass: PropTypes.string,
    // customClass: PropTypes.string,
    // children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    // onClick: PropTypes.func,
    // href: PropTypes.string,
    // to: (props, propName, componentName) => {
    //     if (props['routerLink'] && !props[propName]) {
    //         return new Error(`Invalid prop '${propName}' '${props[propName]}' supplied to '${componentName}'.`);
    //     }
    // },
    // routerLink: PropTypes.bool,
    // activeClassName: PropTypes.string,
    // target: PropTypes.string,
    // download: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

Template.defaultProps = {
    colorVariant: 'gray-white',
    caption: '',
    menu: [],
    customCaption: null,
    customClass: '',
    // customClass: '',
    // isUnderlined: true,
    // onClick: undefined,
    // href: '#',
    // routerLink: false,
    // activeClassName: 'agp-link_pressed',
    // target: '_blank',
    // download: false,
};
