import React from 'react';

import './Heading.scss';
import PropTypes from 'prop-types';
import CircleNotification from "../circleNotification/CircleNotification.jsx";

const Heading = ({children,variant, size, customClass, isNotified, notifyCount, textAlign, ...props}) => {
    let className = `agp-h agp-${variant} agp-${variant}_size_${size} ${customClass ? customClass : ''}`

    let content = <h1 className={className}>{children}</h1>

    switch (variant) {
        case 'h1':
            content = <h1  className={className}>{children}</h1>
            break
        case 'h2':
            content = <h2 className={className}>{children}</h2>
            break
        case 'h3':
            content = <h3 className={className}>{children}</h3>
            break
        case 'h4':
            content = <h4 className={className}>{children}</h4>
            break
        case 'h5':
            content = <h5 className={className}>{children}</h5>
            break
        case 'h6':
            content = <h6 className={className}>{children}</h6>
            break
    }

    return (
        <div className={`agp-heading flex-line agp-heading_${textAlign}`}>
            <div className='agp-heading__content'>
                {content}
            </div>
            { isNotified && <CircleNotification type={"large"} marginLeft={"8px"} marginRight={"0px"} count={notifyCount} /> }
        </div>
    );
};

export default Heading;


Heading.propTypes = {
    variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
    size: PropTypes.oneOf(['medium', 'small']),
    customClass: PropTypes.string,
    isNotified: PropTypes.bool,
    notifyCount: PropTypes.number,
    textAlign: PropTypes.oneOf(['left', 'right']),
};

Heading.defaultProps = {
    size: 'medium',
    customClass: '',
    children: '',
    isNotified: false,
    textAlign: 'left',
};
