import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Icon from "../icon/Icon";
import {gray90, white} from "../vars";
import {connect} from "react-redux";

import './BallonHelp.scss';


const BalloonHelp = (props) => {
    const {
        content,
        position,
        type,
        windowWidth
    } = props;

    const wrapper = useRef(null);
    const description = useRef(null);
    const [isShow, setIsShow] = useState(false);
    const [contentOffset, setContentOffset] = useState([]);
    const [contentVerticalOffset, setContentVerticalOffset] = useState([]);

    const contentStyle = useMemo(() => {
        const offset = contentOffset.reduce((acc,i)=>(acc + i),0);
        const verticalOffset = contentVerticalOffset.reduce((acc, i) => acc + i, 0);
        const stylesObj = {
            "top": () => {
                return {
                    top: `${-8 + verticalOffset}px`,
                    left: !offset ? '50%' : `calc(50% + ${offset}px)`,
                    transform: 'translate(-50%,-100%)',
                }
            },
            "top-right": () => {
                return {
                    top: `${-8 + verticalOffset}px`,
                    right: !offset ? '0' : `${-offset}px`,
                    transform: 'translate(80%,-100%)',
                }
            },
            "bottom-right": () => {
                return {
                    bottom: `${-8 - verticalOffset}px`,
                    right: !offset ? '0' : `${-offset}px`,
                    transform: 'translate(80%,100%)',
                }
            },
            "bottom": () => {
                return {
                    bottom: `${-8 - verticalOffset}px`,
                    left: !offset ? '50%' : `calc(50% + ${offset}px)`,
                    transform: 'translate(-50%,100%)',
                }
            },
            "bottom-left": () => {
                return {
                    bottom: `${-8 - verticalOffset}px`,
                    left: !offset ? '0' : `${offset}px`,
                    transform: 'translate(-80%,100%)',
                }
            },
            "top-left": () => {
                return {
                    top: `${-8 + verticalOffset}px`,
                    left: !offset ? '0' : `${offset}px`,
                    transform: 'translate(-80%,-100%)',
                }
            },
        }
        return stylesObj[position]();

    }, [contentOffset, contentVerticalOffset, position])

    let colorIcon = gray90;
    switch (type) {
        case 'primary':
            colorIcon = gray90;
            break;
        case 'secondary':
            colorIcon = white;
            break;
        case 'white':
            colorIcon = isShow ? white : gray90;
            break;
    }

    useEffect(() => {
        const handleClickOutside = event => {
            if (wrapper.current && !wrapper.current.contains(event.target)) {
                hideBalloon();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapper]);

    const setPositionHandler = (el) => {
        if (el && isShow) {
            let elLeft = el.getBoundingClientRect().left;
            let elRight = el.getBoundingClientRect().right;
            let elTop = el.getBoundingClientRect().top;
            let elBottom= el.getBoundingClientRect().bottom;

            let documentWidth = document.documentElement.clientWidth;
            let documentHeight = document.documentElement.clientHeight;
            let leftOffset = elLeft < 0 ? -elLeft : null;
            let rightOffset = documentWidth - elRight < 0 ? documentWidth - elRight : null;
            let topOffset = elTop < 0 ? -elTop : null;
            let bottomOffset = documentHeight - elBottom < 0 ? documentHeight - elBottom : null;


            if(topOffset !== null) {
                setContentVerticalOffset(prevContentVerticalOffset => ([...prevContentVerticalOffset, topOffset]));
            }

            if(bottomOffset !== null) {
                setContentVerticalOffset(prevContentVerticalOffset => ([...prevContentVerticalOffset, bottomOffset]));
            }

            if (leftOffset !== null) {
                setContentOffset((prevContentOffset) => ([...prevContentOffset, leftOffset]));
            }

            if (rightOffset !== null) {
                setContentOffset((prevContentOffset) => ([...prevContentOffset, rightOffset]))
            }
        } else {
            setContentOffset([]);
            setContentVerticalOffset([]);
        }
    }

    useEffect(() => {
        setPositionHandler(description.current);
    }, [windowWidth, isShow])

    const toggleBalloon = () => {
        setIsShow(prev => !prev);
    }

    const hideBalloon = () => {
        setIsShow(false);
    }
    const preventHideBalloon = (e) => {
        e.stopPropagation()
    }
    return (
        <div
            className={`agp-balloon__wrapper agp-balloon__wrapper-${type} ${isShow ? 'agp-balloon__wrapper-show' : ''}`}
            onClick={toggleBalloon}
            ref={wrapper}
        >
            {isShow &&
                <div
                    onClick={preventHideBalloon}
                    ref={description}
                    className={`agp-balloon__content agp-balloon__content-${type}`}
                    style={contentStyle}
                >
                  {content}
                </div>
            }
            <Icon icon={isShow ? 'Mini/Close' : 'Mini/Info'} width={isShow ? 10 : 2} height={isShow ? 10 : 10}
                  color={colorIcon}/>
        </div>
    );
};

BalloonHelp.defaultProps = {
    position: 'top',
    type: 'primary',
}

BalloonHelp.propTypes = {
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    position: PropTypes.oneOf(['top', 'top-right', 'top-left', 'bottom', 'bottom-right', 'bottom-left']),
    type: PropTypes.oneOf(['primary', 'secondary', 'white']),

}

const mapStateToProps = store => {
    return {
        windowWidth: store.metaState.windowWidth,
    }
}

export default connect(mapStateToProps)(BalloonHelp);