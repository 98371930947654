import React, {useEffect, useRef} from 'react';
import PropTypes from "prop-types";
import './ModalDialog.scss';
import { Modal } from 'react-bootstrap';
import Icon from "../icon/Icon";
import {gray90} from "../vars";


const ModalDialog = (props) => {

    const {
        onHide,
        centered,
        children,
        isShow,
        withCloseBtn,
        paddingTop,
        className,
        size,
        dialogClassName,
        type,
    } = props;


    const handleHideModal = () => {
        if(onHide instanceof Function) {
            onHide();
        }
    }

    return (
        <Modal size={size} centered={centered} onHide={handleHideModal} show={isShow} dialogClassName={`${dialogClassName} agp-modalDialog_${type}`} >
            <div className={`agp-modalDialog ${className} ${paddingTop? `agp-modalDialog__paddingTop_${paddingTop}` : ''}`}>
                <>
                    {withCloseBtn && <span className={'agp-modalDialog__close'} onMouseDown={e => e.preventDefault()} onClick={handleHideModal} tabIndex={0}>
                        <Icon icon={'Mini/Close'} color={gray90} width={10} height={10} />
                    </span>}
                    {children}
                </>
            </div>
        </Modal>
    );
};

export default ModalDialog;

ModalDialog.propTypes = {
    onHide: PropTypes.func,
    centered: PropTypes.bool,
    isShow: PropTypes.bool.isRequired,
    withCloseBtn: PropTypes.bool,
    size: PropTypes.oneOf(['sm','md', 'lg', 'xl']), // sm - mobile, md-small, lg - medium, xl-large
    paddingTop: PropTypes.number,
    className: PropTypes.string,
    dialogClassName: PropTypes.string,
    type: PropTypes.oneOf(['info', 'form']),
};

ModalDialog.defaultProps = {
    onHide: undefined,
    centered: true,
    isShow: true,
    withCloseBtn: true,
    size: 'md',
    paddingTop: 40,
    className: '',
    dialogClassName: '',
    type: 'form',
};
