import React from 'react';
import PropTypes from "prop-types";


import './CircleNotification.scss'

const CircleNotification = ({ type, marginLeft, marginRight, count }) => {
    return (
        <span style={{marginLeft: marginLeft, marginRight: marginRight}} className={`agp-circle__notification type-${type}`}>
            {count}            
        </span>
    );
};

CircleNotification.propTypes = {
    type: PropTypes.string.isRequired,
    marginLeft: PropTypes.string.isRequired,
    marginRight: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired
};

CircleNotification.defaultProps = {
    type: 'large',
    marginLeft: '0px',
    marginRight: '0px',
    count: 0
};


export default CircleNotification;